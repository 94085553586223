.claim-todo {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  position: relative;
  min-height: 45px;
  height: max-content;
  .loader {
    margin-top: -5px;
    margin-left: 8px;
    opacity: 0;
    transition: 0.1s all ease-in-out;
    flex-grow: 1;
  }
  .invoice-dropzone {
    border-color: #ccc;
  }
}

.claim-todo.complete {
  color: #ccc;
  .checkbox-container {
    color: #ccc;
    svg .square {
      stroke: #ccc;
      fill: #ccc;
    }
  }
  .loader {
    opacity: 1;
  }
  .date-picker {
    pointer-events: none;
    opacity: 0.5;
  }
  &.confirmed {
    pointer-events: none;

    > .todo-toggle-div {
      > label {
        color: #ccc;
      }
      > .input-switch {
        opacity: 0.5 !important;
      }
    }
    .loader {
      opacity: 0;
    }
    .checkbox-container {
      text-decoration: line-through;
    }
  }
}

.todo-drop-file {
  border-color: #aaa;
  display: block;
  width: 100%;
  min-width: 100%;
  flex-grow: 1;
  margin: 5px auto;
  margin-top: 10px;
  border: 2px dashed #ccc;
  width: 100%;
  display: flex;
  color: #ccc;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;
  height: 70%;
}

.claim-todo.file {
  text-decoration: none;
  color: #aaa;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  .checkbox-container {
    pointer-events: none;
  }
  .todo-drop {
    border-color: #aaa;
    display: block;
    width: 100%;
    min-width: 100%;
    flex-grow: 1;
    margin: 5px auto;
    margin-top: 10px;
  }
  svg .square {
    stroke: #ccc;
  }

  // &.complete {
  //   .todo-drop {
  //     display: none;
  //   }
  // }

  &.has-file:not(.complete) {
    color: #333;
    opacity: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .checkbox-container {
      pointer-events: all;
      opacity: 1;
    }

    svg .square {
      stroke: #aaa;
    }
  }
}

.claim-todo.disabled {
  pointer-events: none;
  text-decoration: none;
  color: #ccc;
  .todo-drop {
    display: none;
    opacity: 1;
    border-color: #ccc;
  }
  svg .square {
    stroke: #ccc;
  }
  > .todo-toggle-div {
    pointer-events: none;
    > label {
      color: #ccc;
    }
    > .input-switch {
      opacity: 0.5 !important;
    }
  }
}

.claim-todo.outgoing {
  color: #aaa;
  .checkbox-container {
    color: #aaa;
    svg .square {
      stroke: #ccc;
      fill: #ccc;
    }
  }
  > .todo-toggle-div {
    pointer-events: none;
    > label {
      color: #ccc;
    }
    > .input-switch {
      opacity: 0.5 !important;
    }
  }
  .date-picker {
    pointer-events: none;
    opacity: 0.5;
  }
}

// Validation
.is-valid.input-group,
.is-valid.form-group,
.is-valid.date-picker {
  input,
  .input-group-prepend > .input-group-text,
  textarea {
    border-color: $success !important;
    background-image: none !important;
  }
  .select-dropdown > div {
    border-color: $success !important;
  }
  .phone-country-dropdown > div {
    border-left-color: $success !important;
    border-top-color: $success !important;
    border-bottom-color: $success !important;
  }
}
