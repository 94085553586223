.newsticker {
  min-width: 40%;
  padding: 0.5rem;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 20px;
  text-decoration: none;
  margin-left: -10px;
  width: 110%;
}

.newsWrapper {
  display: flex;
  justify-content: end;
  // width: -65%;
  align-items: flex-start;
  margin-left: 1500px;
}

.newsTitle {
  text-overflow: ellipsis;
  word-wrap: none;
  white-space: nowrap;
  max-width: auto;
  overflow: hidden;
}

.title:hover {
  text-decoration: underline;
}

.meta {
  margin-top: 3px;
  font-size: 14px;
}

.newsIcon {
  width: 10px;
}

.newsIcon img {
  width: 1%;
}