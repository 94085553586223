@import './variables.scss';

$datepicker-border-radius: 4px;

.date-picker {
  margin-bottom: 15px;
}

.SingleDatePickerInput {
  border-radius: $datepicker-border-radius;
  .DateInput {
    border-radius: $datepicker-border-radius;
    input {
      border-radius: $datepicker-border-radius;
    }
  }
}

.DateInput_input__focused {
  border-bottom: 2px solid $secondary;
  
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: $secondary; //background
  color: white; //text
  border: 1px solid lighten($secondary, 20%); //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $secondary;
  color: white;
  border: 1px solid lighten($secondary, 20%); //default styles include a border
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: lighten($secondary, 20%);
  border: 1px solid lighten($secondary, 20%); //default styles include a border
  color: white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $secondary;
}