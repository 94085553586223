// Colors
$bodyBackgroundColor: #fff;
$brandPrimary: #e1271b;
$brandSecondary: #0f2453;
$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray: #6c757d;
$gray-dark: #343a40;
$primary: #007bff;
$secondary: #6c757d;
$success: #36C77A;
$info: #0180ff;
$warning: #ffc107;
$danger: #fa5240;
$light: #f8f9fa;
$dark: #343a40;

$primary: $brandPrimary;
$secondary: $brandSecondary;


// Containers
$containerBorderRadius: 10px;
$containerPadding: 25px;
$sidebarBackground: #0f2453;
$sidebarTextColor: #fff;
$mainBackground: #f0f1f4;
$sidebarActiveBackground: #3c4c6f;

$sidebarWidth: 75px;

$sidebarBorderRadius: 0px;
$sidebarCorners: $sidebarBorderRadius;