@import './global.scss';
@import './variables.scss';

.side-panel-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  pointer-events: none;
  background: transparent;
  opacity: 1;
  transition: 0.1s all ease-in-out;
  &.open {
    background: rgba(0, 0, 0, 0.4);
  }
}

.incoming-claim-page-container {
  padding: 15px;
  height: 97vh;
  overflow: auto;
}

.table thead th {
  vertical-align: middle;
  border: none;
  font-size: 10pt;
  padding: 0.25em 0.75em;
}

.table tbody td {
  font-size: 10pt;
  padding: 0.15em 0.75em;
  border: 0;
  vertical-align: middle;
}

.width-7 {
  width: 7% !important;
}

.width-20 {
  width: 20% !important;
}

.justify-left {
  justify-content: start !important;
}

.table-all-header {
  width: 100%;
  > div {
    display: flex;
    flex: 1;
    > div {
      width: 11.5%;
      vertical-align: middle;
      border: none;
      font-size: 10pt;
      padding: 0.5em;
      font-weight: 700;
    }
    > div:last-child {
      width: 3%;
    }
    > div:nth-last-child(2) {
      width: 3%;
    }
  }
}

.table-all-header-user {
  margin-top: 30px;
  width: 100%;
  > div {
    display: flex;
    flex: 1;
    > div {
      width: 20%;
      vertical-align: middle;
      border: none;
      font-size: 10pt;
      padding: 0.25em 0.75em;
      font-weight: 700;
    }
    > div:nth-child(3) {
      width: 20%;
      > i {
        cursor: pointer;
      }
    }
  }
}

.table-all-row {
  display: flex;
  width: 100%;
  > div {
    display: flex;
    width: 11.5%;
    font-size: 10pt;
    padding: 0.1em 0.5em;
    border: 0;
    align-items: center;
    justify-content: center;
    word-break: break-all;
  }
  > div:last-child {
    width: 3%;
  }
  > div:nth-last-child(2) {
    width: 3%;
    > i {
      cursor: pointer;
    }
  }
}

.table-all-row-user {
  display: flex;
  width: 100%;
  > div {
    display: flex;
    width: 20%;
    font-size: 10pt;
    padding: 0.15em 0.75em;
    border: 0;
    align-items: center;
    word-break: break-all;
  }
  > div:nth-child(3) {
    width: 20%;
    > i {
      cursor: pointer;
    }
  }
}

.table-inline-button,
.table-inline-button:hover,
.table-inline-button:active,
.table-inline-button:focus {
  background: transparent;
  background-color: transparent;
  color: $brandPrimary;
  line-height: 1;
  opacity: 0.6;
  transition: 0.1s opacity ease-in-out;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background: transparent !important;
  padding: 5px 0 !important;
  text-align: left;
  &:hover {
    opacity: 1;
    color: $secondary;
  }

  &:active {
    opacity: 0.9;
    color: #444 !important;
  }
}

.side-panel {
  position: absolute;
  width: 75vw;
  height: 98vh;
  top: 1vh;
  right: 0;
  box-shadow: -1px 0px 15px -4px;
  border-radius: 15px 0 0 15px;
  padding: 0px 10px;
  background: white;
  z-index: 200;
  transition: all 0.15s ease-in-out;
  transform: translateX(100%);
  z-index: 200;
  .close-btn,
  .close-btn:hover,
  .close-btn:active,
  .close-btn:focus {
    background: transparent !important;
    background-color: transparent !important;
    color: #444 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 32pt;
    line-height: 18pt;
    opacity: 0.8;
    transition: 0.1s opacity ease-in-out;
    padding: 0px 15px 0px 0px !important;
    min-width: fit-content;

    &:hover {
      opacity: 1;
    }
    &:active {
      opacity: 0.6;
    }
  }
  &.open {
    transform: translateX(0);
  }

  .claim {
    margin-top: 10px;
    height: 85vh;
  }
}

.download-btn {
  width: 200px;
  margin-left: 15px;

  > i {
    margin-left: 5px;
  }
}

.todo-list-container {
  > .static > .todo-list {
    overflow: auto;
    padding-right: 10px;
    margin-bottom: 20px;
  }
  padding: 0px;
  padding-left: 20px;
  h3 {
    font-size: 1.25em;
    font-weight: bold;
    margin: 0px;
  }
}

.claim-todo {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .date-picker {
    margin: 0 0 5px 25px;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    .Checkbox {
      margin-top: -15px;
    }

    label {
      margin: 0px;
      margin-left: 10px;
    }
  }
}

.claim-ownership {
  padding: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  .own-claim {
    border-radius: 5px;
  }
}

.edit-info-collapse {
  max-height: 66vh;
  overflow: auto;
}

// Fake inputs start
.fake,
.static {
  .invoice-dropzone {
    border: 2px dashed #ccc;
    height: 150px;
    width: 100%;
    display: flex;
    color: #ccc;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      color: #aaa;
      border-color: #aaa;
    }
    &.todo-drop {
      max-width: 132px;
      height: auto;
      padding: 0.5em;
      text-align: center;
      pointer-events: all !important;
    }
  }

  .notes {
    height: 55vh;
  }

  .past-notes {
    border: 1px solid #ccc;
    border-radius: 10px 10px 0 0;
    margin: 25px auto -1px auto;
    margin-top: 10px;
    background-color: #e1e7f5;
    .empty-notes,
    .loading-notes {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 25px 25px 10px 25px;
      color: #ccc;
    }
    .note {
      margin: 10px;
      padding: 10px;
      border-radius: 10px;
      background: white;
      position: relative;
      .note-body {
        .note-title {
          color: #ccc;
          margin: 0;
          font-size: 9pt;
          margin-bottom: 8px;
        }
        padding-right: 50px;
        color: $brandSecondary;
        margin: 0;
      }
      .note-data {
        color: #ccc;
        margin: 0;
        position: absolute;
        top: 2px;
        right: 10px;
        font-size: 8pt;
        text-align: right;
      }
    }
  }
  .note-input {
    max-height: 45px;
    display: flex;
    textarea {
      resize: none;
      border-radius: 0 0 0 10px !important;
    }
    button {
      border-radius: 0 0 10px 0 !important;
    }
  }
  .claim-buttons {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      border-radius: 5px;

      &:first-child {
        margin-right: 10px;
      }
    }
    &.lonely {
      button:first-child {
        margin-right: 0;
      }
    }
  }
  .sidepanel-buttons {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      border-radius: 5px;
      flex: 1;

      &:first-child:not(.complete-claim) {
        margin-right: 10px;
      }
    }
    &.lonely {
      button:first-child {
        margin-right: 0;
      }
    }
  }
}

.claim-user-icon {
  width: 20px;
  height: 20px;
  line-height: 15px;
  border-radius: 100%;
  color: #fff;
  background: $brandSecondary;
}

.icons-div {
  display: flex;
  pointer-events: all;

  > .btn:focus {
    box-shadow: none !important;
  }
}

.file-list,
.file-single {
  padding: 15px 0 0 0;
  .file {
    cursor: pointer;
  }
}
button.file-single {
  padding: 0.25em 1em !important;
  padding-right: 0px !important;
  display: flex;
  align-items: center;
  box-shadow: none !important;
}

.trash {
  color: $danger;

  &:hover {
    color: $brandPrimary;
  }
}

.image-modal-close {
  position: fixed;
  background: transparent !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  top: 35px;
  right: 15px;
}

.max-height-sidebar {
  max-height: 82vh;
}

.info-div {
  padding: 6px;
  margin: 5px 0px;
  background: #ffc107;
  display: flex;
  align-items: center;
  .info-icon {
    display: flex !important;
    margin-right: 10px;
  }
}

.sidebar-info-scrollbar {
  margin-bottom: 10px;

  &.info-only {
    max-height: 85vh;
  }
  .claim-group {
    position: relative;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .claim-group-label {
      font-weight: bold;
      padding: 0;
      flex: 1 1;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      background: transparent;
      border-color: $secondary;
      border-width: 0;
      border-style: solid;
      div {
        transform-origin: 0 0;
        width: 100%;
        font-size: 1.25em;
        white-space: nowrap;
        margin-left: 10px;
      }
    }
    .sidepanel-info-table {
      margin: 0;
      tr > td {
        padding: 5px 0.75em;
      }
      .form-group {
        margin: 0;
      }
    }
  }
}

.position-fixed-btns {
  position: fixed;
  top: 2vh;
  right: 10px;
  width: 400px;
}

.edit-buttons {
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-height: 50px;
  button {
    width: 50%;
  }
  button:not(:last-child) {
    margin-right: 10px;
  }
}

.accordion-btn {
  padding: 0px !important;
  padding: 10px !important;
  font-size: 1rem;
  width: 100%;
  text-align: start;
  margin-bottom: 10px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.header-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 9vh;
  > div {
    display: flex;
    align-items: center;
    > .accept-claim-btn {
      margin-left: 20px;
      width: 130px;
      padding: 10px !important;
    }
    > .cancel-claim-btn {
      width: 130px;
      padding: 10px !important;
    }
  }
  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    .status-badge {
      font-size: 0.5em;
      margin-right: 15px;
      margin-top: 5px;
      margin-left: 10px;
      background-color: $secondary;
    }
  }
  .edit-buttons > button {
    margin-left: 0.5em;
    margin-right: 0.5em;
    &:last-child {
      margin-right: 0;
    }
  }
}

.job-cost-estimate {
  margin: 35px 0 15px 0;
  text-align: center;
  padding: 0;
}

.accept-claim-disclaimer {
  text-align: center;
}

.small-font {
  font-size: 12px;
}

.check-icon {
  color: $green;
  margin-left: 10px;
  cursor: pointer;
}

.ml-10 {
  margin-left: 10px;
}
