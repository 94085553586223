@import './global.scss';
@import './variables.scss';

.newsticker {
  position: absolute;
  bottom: 0;
  width: 100%;
}


div.dashboard-page-container {

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div.user-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    top: 25px;
    right: 25px;
    font-weight: 600;
  }

  div.dashboard-tile-container {
    display: flex;
    height: 100vh;
    align-items: center;
    button.claim-tile {
      padding: 30px;
      border: 2px solid transparent; 
      border-radius: 15px;
      border-width: 0;
      font-weight: 400 !important;
      background: white;
      color: #444 !important;
      width: 250px;
      height: 250px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: 0.15s box-shadow ease-in-out;

      strong {
        opacity:0;
        position:absolute;
        left:-120px;
        transform:skewX(0deg); 
        transition: all .12s ease;
        top:2px;
      }
      span,.claim-tile-badge {
        overflow:hidden;
        background:$primary;
        color:white;
        border-radius:9999px; 
        transform:skewX(0deg);
        position:absolute;
        top:-8px;
        right:-8px;
        transition:all .12s ease, border-radius .005s ease-out;
        height:30px;
        width:30px; 
        font-size:1em;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:0;
        strong,span {
          margin-top: -3px;
          line-height: 30px;
        }
      }
      
      > span >span {
        transform:skewX(0deg);
        position:absolute;
        right:0;
        left:0;
        top:2px;
      }
      
      &:hover strong {
        opacity:1;
        left:10px;
        transform:skewX(7.5deg);
      }
      &:hover > span {
        transform:skewX(-7.5deg);
        background:$primary;
        border-radius:4px 8px 4px 8px;
        width:67%; 
        position:absolute;
        top:-5px;
        right:-5px;
      }
      &:hover > span >span {
        transform:skewX(7.5deg)!important;
        right :15px;
        left:auto;
      }

      &:not(:first-child) {
        margin-left: 30px;
      }

      .title, .subtitle, .counter {
        text-align: center;
      }
      .counter {
        font-size: 32pt;
        line-height: 32pt;
        font-weight: bold;
      }
      .title {
        font-size: 24pt;
        line-height: 26pt;
        margin-bottom: 10px;
      }

      &:hover:not(:disabled) {
        border: 2px solid transparent !important;
        box-shadow: 0 0 16px -6px #aaa !important;
      }

      &:active:not(:disabled) {
        border: 2px solid transparent;
        box-shadow: 0 0 6px -4px #444;
        background: white;
        color: #444;
      }

      &:disabled, &.disabled {
        color: #444;
        box-shadow: none;
        opacity: 0.5 !important;
        cursor: default;
      }

    }
  }
}